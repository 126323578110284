import * as _ from "lodash";
import { useEffect, useState } from "react";

import { Select } from "@braze/beacon-lab-select";

import { AccountResult } from "src/hooks/types";
import { useGetCompanies } from "src/hooks/useGetCompanies";
import { OptionsLabelValue } from "src/components/types";
import styled from "styled-components";

const TIMEOUT_VAL = 500;

const StyledWarningText = styled.div`
  color: red;
  font-size: 12.5px;
  margin-top: -0.15rem;
  margin-bottom: 0.62rem;
  display: flex;
  align-items: center;

  img {
    margin-right: 6px;
    padding-bottom: 2px;
  }
`;

type AccountSearchSelectProps = {
  onSelectAccount: (selectedCompany?: AccountResult) => void;
};

export const AccountSearchSelect = ({
  onSelectAccount,
}: AccountSearchSelectProps) => {
  const [accountOptions, setAccountOptions] = useState<OptionsLabelValue[]>([]);

  const { value, error, loading, refetch } = useGetCompanies(true);

  // refetch companies with debounce
  const fetchCompanies = _.debounce((companyPrefix) => {
    if (companyPrefix) {
      refetch({
        name: companyPrefix,
      });
    }
  }, TIMEOUT_VAL);

  useEffect(() => {
    const res = (value || []).map((company: AccountResult) => ({
      label: company.ACCOUNT_NAME,
      description: `ID: ${company.ACCOUNT_ID}`,
      value: company,
    }));

    setAccountOptions(res);
  }, [value]);
  return (
    <>
      {!!error && (
        <StyledWarningText>
          <img src="public/img/warning.svg" alt="warning icon" />
          Cannot connect to database.
        </StyledWarningText>
      )}
      <Select
        inputId="account-name"
        isClearable
        isDisabled={false}
        isSearchable
        fluid
        isLoading={loading}
        backspaceRemovesValue
        onMenuClose={() => {
          setAccountOptions([]);
        }}
        options={accountOptions}
        menuPortalTarget={document.body}
        placeholder="Type in to see results..."
        onInputChange={fetchCompanies}
        onChange={(selectedOption) => {
          if (selectedOption) {
            onSelectAccount(selectedOption.value);
          } else {
            onSelectAccount(undefined);
          }
        }}
      />
    </>
  );
};
