import {
  BodyText,
  Button,
  FieldLabel,
  Flex,
  Heading,
  Panel,
  PanelBody,
  Textarea,
} from "@braze/beacon-core";
import { getColor } from "@braze/beacon-styling";
import styled from "styled-components";

export const RequestForm = styled(Flex)`
  height: auto;
  margin: 2rem 2rem 12.5rem 2rem;
`;

export const FormDetails = styled(Flex)`
  flex-direction: column;
`;

export const SubText = styled.span`
  font-size: 12.5px;
  color: rgb(94, 108, 117);
  margin-bottom: 0.8rem;
`;

export const StyledHomeButton = styled(Flex)`
  margin: 2rem;
  position: absolute;
  left: 0;
`;

export const FormPanel = styled(Panel)`
  box-shadow: none;
`;

export const FormPanelBody = styled(PanelBody)`
  width: 600px;
  box-shadow: 0 8px 16px 0 rgba(69, 84, 94, 0.1);
  border-top: 1px solid #8080802b;
  padding: 2.5rem;
`;

export const FormPanelBodyFull = styled(PanelBody)`
  width: 1300px;
  box-shadow: 0 8px 16px 0 rgba(69, 84, 94, 0.1);
  border-top: 1px solid #8080802b;
  padding: 2.5rem;
`;

export const FormHeading = styled(Heading)`
  margin-bottom: 0.5rem;
`;

export const SubmitButton = styled(Button)`
  margin-top: 2rem;
`;

export const Confirmation = styled(FieldLabel)`
  span {
    bottom: 20px;
    left: 165px;
    position: absolute;
  }
`;

export const TopMargin = {
  marginTop: "2rem",
};

export const ComingSoon = styled(Flex)`
  &Label {
    font-weight: 500;
  }
`;

export const InputLabel = styled(FieldLabel)``;

export const LongLabel = styled(FieldLabel)`
  white-space: break-spaces;
  height: auto;
`;

export const MarginalizedTextArea = styled(Textarea)`
  margin-left: 0.5rem;
`;

export const Hyperlink = styled.a`
  color: ${getColor("lightBlue", 500)};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledBodyText = styled(BodyText)`
  border: 1px solid #008294;
  padding: "1rem";
  border-radius: "4px";
  background-color: #f3feff;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

export const CommonStyles = () => <></>;
