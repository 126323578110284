export enum EbrSlidebotTemplates {
  EBR2 = "EBR_2.0_TEMPLATE_SLIDEBOT",
}

export const EbrReportType = [
  { label: "Year Over Year", value: "YoY" },
  { label: "Quarter Over Quarter", value: "QoQ" },
];

export const EbrAttributionWindow = [
  { label: "1 Day", value: "1D" },
  { label: "3 Days", value: "3D" },
  { label: "7 Days", value: "7D" },
  { label: "30 Days", value: "30D" },
];

export const EbrExecutiveSummary = [
  { label: "Attributed Revenue", value: "Attributed Revenue" },
  { label: "Sessions", value: "Sessions" },
];

export const EbrValueDrivers = [
  "Maximize Revenue & Profit",
  "Build Brand Equity with Every Interaction",
  "Reduce Cost & Eliminate Complexity",
  "Reduce Business Risk Today & As We Scale",
];

export const EbrSubGoals = {
  "Maximize Revenue & Profit": [
    "Drive greater Revenue from your data and your customers",
    "Drive greater Engagement from your data and your customers",
    "Ensure quality user acquisition",
    "Reactivate passive / dormant / lapsed users",
    "Activate new users to fuel growth",
    "Drive purchase volumes & purchase frequency",
    "Increasing the value of free users",
    "Increasing customer lifetime value",
    "Drive revenue from advertising",
    "Improve our Path to Profitability",
    "Drive Adoption / Uptake of <Customer's> Product",
  ],
  "Build Brand Equity with Every Interaction": [
    "Enable greater creativity from simple notifications to breakthrough campaigns & programs",
    "Increase Daily & Monthly Active Users",
    "Increase & Retain Subscribers",
    "Increase User Retention",
    "Increase User Engagement Email",
    "Increase User Engagement Push",
    "Increase User Engagement SMS",
    "Increase User Engagement WhatsApp",
    "Improve CTR of Customer Communications Comparison - Content Card",
    "Improve CTR of Customer Communications Delta - Content Card",
    "Improve CTR of Customer Communications Comparison - Email",
    "Improve CTR of Customer Communications Delta - Email",
    "Drive Cross- and Upsell efforts",
    "Increasing Monetization of Different Features for <Customer>",
  ],
  "Reduce Cost & Eliminate Complexity": [
    "Increase Speed to Set up Campaigns",
    "Increase Collaboration Across <Customer> Teams",
    "Increase Speed to Market (less manual work and faster QA'ing internally)",
    "Increase Internal Visibility of Communications",
    "Improve ROI of the Tech Stack",
    "Reduce Platform Downtime",
    "Reduce Complexity Surrounding Comms Strategy",
    "Drive New Feature Adoption",
  ],
  "Reduce Business Risk Today & As We Scale": [
    "Rely on trusted infrastructure to meet business demands and deliver results without compromise",
    "Convert anonymous users to known users",
    "Activate new, quality users to fuel growth",
    "Reactivate passive/dormant / lapsed users",
    "Test out new comms channels using Braze",
    "Improve campaign measurement and testing",
    "Enhance Segmentation to improve user targeting",
  ],
};

export const EbrMetrics = {
  "Maximize Revenue & Profit": [
    "Total Last Touch Revenue",
    "Revenue by Schedule Type - API Triggered",
    "Revenue by Schedule Type - Action Based",
    "Revenue by Schedule Type - Scheduled",
    "Total Purchase Conversions",
    "Total Purchase Conversions by Schedule Type - API Triggered",
    "Total Purchase Conversions by Schedule Type - Action Triggered",
    "Total Purchase Conversions by Schedule Type - Scheduled",
    "Average Days to Purchase",
  ],
  "Build Brand Equity with Every Interaction": [
    "Quarter/YTD Total Active Users",
    "iOS Users",
    "Android Users",
    "Web Users",
    "Total Number of Sessions",
    "Total Sessions Completed",
    "Total Number of Active Users",
    "Avg. Session Duration per Active User",
    "Avg. No. of Sessions per Active User",
    "Total Push Sent",
    "Total Push Open Rate",
    "Direct Push Open Rate",
    "Influenced Push Open Rate",
    "Total Email Sent",
    "Email Click Rate",
    "Email Clickthrough Rate",
    "Total Content Card Sent",
    "Content Card Click Rate",
    "Total IAM Impressions",
    "Total IAM Click Rate",
  ],
  "Reduce Cost & Eliminate Complexity": [
    "Measuring of this needs to be defined",
  ],
  "Reduce Business Risk Today & As We Scale": [
    "Measuring of this needs to be defined",
  ],
};
